import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay])

export default function sliders() {
	// get all sliders, we need to loop them due to different settings + nav
	var swipers = document.querySelectorAll(
		'.swiper-container:not(.control):not(.mobile)'
	)
	swipers.forEach(function (el, index) {
		var closestSection = el.closest('section')
		var controls = closestSection.querySelector('.control')
		var isTestimonials = closestSection.classList.contains('testimonials')

		// slider settings
		var options = {
			speed: 600,
			loop: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			thumbs: {},
		}

		// For gallery sliders
		if (controls) {
			options.thumbs.swiper = new Swiper(controls, {
				speed: 600,
				loop: true,
				slidesPerView: 2,
				spaceBetween: 10,
				freeMode: true,
				centeredSlides: true,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: true,
				},
				breakpoints: {
					640: {
						slidesPerView: 3,
					},
					992: {
						slidesPerView: 4,
					},
				},
			})
		}

		if (isTestimonials) {
			options.slidesPerView = 1
			options.breakpoints = {
				996: {
					slidesPerView: 2,
                    spaceBetween: 30
				},
			}
		}

		// init slider
		new Swiper(el, options)
	})
}
