export default function getCurrentMonth() {
    var months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	var now = new Date()
	var thisMonth = months[now.getMonth()]
	if (document.querySelector('.output--month')) {
		document.querySelectorAll('.output--month').forEach((el) => {
			el.textContent = thisMonth
		})
	}
    if (document.querySelector('.output--year')) {
		document.querySelectorAll('.output--year').forEach((el) => {
			el.textContent = now.getFullYear()
		})
	}
}
