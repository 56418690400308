export default function viewAnimation() {
    var items = document.querySelectorAll(".view-animation");
    if (items) {
      let options = {
          root: null,
          rootMargin: "0px",
          threshold: .25,
      };
  
      var callback = function (entries, observer) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
           entry.target.classList.add('animated');
          }
        });
      };
      let observer = new IntersectionObserver(callback, options);
      items.forEach(function(element) {
          observer.observe(element);
      })
    }
}
