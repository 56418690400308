import contentLoaded from './vendor/contentloaded.min.js'
import images from './images.js'
import phoneConcat from './phone-concatination.js'
import polyfill from './polyfills.js'
import sliders from './sliders.js'
import stickyHeader from './sticky-header.js'
import viewAnimation from './view-animation.js'
import getCurrentMonth from './month.js'
import './partytown';

contentLoaded()

window.contentLoaded(window, function (e) {
	polyfill()
	phoneConcat()
	images()
	sliders()
	stickyHeader()
	viewAnimation()
	getCurrentMonth()
})
