export default function phoneConcat() {
	var vw = Math.max(
		document.documentElement.clientWidth || 0,
		window.innerWidth || 0
	)
	var phones = document.querySelectorAll('.header-phone')

	if (phones) {
		setTimeout(function () {
			phones.forEach((phone) => {
				var phoneText = phone.querySelector('em')
				if (phoneText) {
					var unsliced = phoneText.textContent
					var sliced = unsliced.slice(0, -2) + '...'

					phoneText.textContent = sliced

					var linked = 'tel:' + unsliced.replace(/\s/g, '')

					phone.addEventListener('click', (e) => {
						if (vw < 1000) {
							window.location.href = linked
						} else {
							phoneText.textContent = unsliced
						}
					})
				}
			})
		}, 2000)
	}
}
